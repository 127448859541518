<template>
  <div class="main-content">
    <b-tabs pills content-class="mt-3">
      <b-tab title="Unpaid Collections" active>
        <b-card>
          <GoodDataTable
            ref="dataTable1"
            class="w-100"
            mode="local"
            :no-serial="true"
            :columns="columns"
            :load-local-data="true"
            :chBoxenabled="true"
            :chkBoxAction="{ active: true, deactive: false, activeLabel: 'Request Withdraw Amount', selectionText: `referrals selected,  Total Amount: $${amountToWithdraw}` }"
            :selectionInfoClass="'selection-info'"
            @enable="requestForWithdrawal"
            @onItemSelected="onItemSelected"
          />
        </b-card>
        <h2 class="mt-3">Requested Collections</h2>
        <b-card>
          <GoodDataTable ref="dataTable3" class="w-100" mode="local" :no-serial="true" :columns="columns" :load-local-data="true" />
        </b-card>
      </b-tab>
      <b-tab title="Paid Collections">
        <b-card>
          <GoodDataTable ref="dataTable2" class="w-100" mode="local" :no-serial="true" :columns="columns" :load-local-data="1 === 1" />
        </b-card>
      </b-tab>
    </b-tabs>
    <div class="row w-100"></div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { dateTimeFormat, isEmpty } from '@/utilities'
import { hideLoader, showLoader, showErrorNotification, showSuccessNotification } from '@/@core/comp-functions/ui/app'

export default {
  name: 'EarningsIndex',
  components: {
    GoodDataTable,
  },
  data: () => ({
    amountToWithdraw: 0,
  }),
  computed: {
    ...mapGetters('affilation', ['earnings']),
    columns: {
      get() {
        return [
          {
            field: 'commission_rate',
            label: 'Commission Percentage',
            sortable: false,
          },
          {
            field: 'amount',
            label: 'Amount',
            useResolver: true,
            useHtml: true,
            renderer: props => `$${props.amount}`,
          },
          {
            field: 'created_at',
            label: 'Date',
            useResolver: true,
            useHtml: true,
            renderer: props => (isEmpty(props.created_at) ? 'N/A' : dateTimeFormat(props.created_at, 'DD MMM, YYYY')),
          },
          {
            field: 'referral_type',
            label: 'Referral Type',
          },
          {
            field: 'referral',
            label: 'Referral',
          },
        ]
      },
    },
  },

  methods: {
    ...mapActions('affilation', ['getEarnings', 'updateEarnings']),
    async requestForWithdrawal(data) {
      if (this.amountToWithdraw < 100) {
        showErrorNotification(this, 'Minimum amount of $100 required for a withdrawal request')
        return false
      }
      const selectedOrders = data.selectedRows || []
      const ids = selectedOrders.map(x => x.id)
      const params = {
        ids,
        is_paid: 2,
      }
      showLoader()
      this.updateEarnings(params)
        .then(r => {
          if (r._statusCode === 200) {
            showSuccessNotification(this, r.message)
          } else {
            showErrorNotification(this, r.message)
          }
          hideLoader()
        })
        .catch(e => {
          hideLoader()
        })
    },
    onItemSelected(data) {
      const selectedData = data.selectedRows || []
      let amount = 0
      selectedData.forEach(i => {
        const totalAmount = parseFloat(i.amount)
        if (!isEmpty(totalAmount)) {
          amount += totalAmount
        }
      })
      this.amountToWithdraw = amount.toFixed(2)
    },
    setData() {
      const paid = this.earnings.filter(i => i.is_paid == 1)
      const unpaid = this.earnings.filter(i => i.is_paid == 0)
      const requested = this.earnings.filter(i => i.is_paid == 2)
      if (this.$refs.dataTable2) {
        this.$refs.dataTable2.setLocalData(paid)
      }
      if (this.$refs.dataTable1) {
        this.$refs.dataTable1.setLocalData(unpaid)
      }
      if (this.$refs.dataTable3) {
        this.$refs.dataTable3.setLocalData(requested)
      }
    },
  },

  mounted() {
    this.getEarnings()
    this.setData()
  },

  watch: {
    earnings: {
      handler(v) {
        this.setData()
      },
    },
  },
}
</script>